import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Icon, Box, Input, Button, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Formspree } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Higland Golf Kulübü
			</title>
			<meta name={"description"} content={"Olağanüstü bir golf deneyimi için ilk destinasyonunuz olan Highland Golf Kulübü'nü keşfedin. Güzel sahamızı, birinci sınıf tesislerimizi keşfedin ve golf tutkunlarından oluşan topluluğumuza katılın."} />
			<meta property={"og:title"} content={"Higland Golf Kulübü"} />
			<meta property={"og:description"} content={"Olağanüstü bir golf deneyimi için ilk destinasyonunuz olan Highland Golf Kulübü'nü keşfedin. Güzel sahamızı, birinci sınıf tesislerimizi keşfedin ve golf tutkunlarından oluşan topluluğumuza katılın."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
		</Helmet>
		<Components.Headernew />
		<Section
			color="--light"
			padding="100px 0"
			sm-padding="40px 0"
			position="relative"
			background="linear-gradient(0deg,rgba(0, 135, 90, 0.49) 0%,rgba(4, 34, 24, 0.49) 100%),--color-darkL2 url(https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z) bottom/cover"
			quarkly-title="Form-1"
		>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" md-width="100%">
					<Text margin="0px 0px 0px 0px" font="--headline2">
						Highland Golf Kulübünü keşfedin
					</Text>
					<Text margin="3rem 0px 0px 0px" font="--base">
						<Strong>
							Highland Golf Kulübünde olağanüstü golf ve topluluk atmosferini deneyimleyin. Kulübümüz hakkında daha fazla bilgi edinmek, bir ziyaret planlamak veya üyeliğe kaydolmak için bugün bizimle iletişime geçin. Sizi kulübümüzde ağırlamayı dört gözle bekliyoruz!
						</Strong>
					</Text>
					<Box>
						<Box
							sm-padding="64px 0 0 0"
							margin="32px 0 0 0"
							max-width="360px"
							position="relative"
							padding="0 0 0 64px"
						>
							<Icon
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdLocationOn}
								position="absolute"
							/>
							<Text as="h4" margin="6px 0" font="--base">
								Bizi ziyaret et
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								Belek Mah. Turizm Cad. No: 12A/A, 07506 Serik/Antalya, Türkiye
							</Text>
						</Box>
						<Box padding="0 0 0 64px" margin="64px 0 0 0" max-width="360px" position="relative">
							<Icon
								left="0"
								category="md"
								icon={MdPhone}
								position="absolute"
								size="48px"
								top="0"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Arama
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								+902427100600
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					align-items="stretch"
					flex-direction="column"
					justify-content="center"
					text-align="right"
				>
					<Box
						padding="56px 48px"
						margin="0 0 0 auto"
						md-max-width="100%"
						background="--color-primary"
						max-width="360px"
					>
						<Text as="h3" font="--headline3" margin="0 0 20px 0">
							Bize mesaj bırakın
						</Text>
						<Formspree endpoint="xeqpgrlv">
							<Box gap="16px" display="flex" flex-direction="row" flex-wrap="wrap">
								<Box padding="8px 8px 8px 8px" width="100%">
									<Box display="flex" flex-direction="column">
										<Text margin="0 0 4px 0" font="--base">
											İsim
										</Text>
										<Input max-width="400px" width="100%" name="name" />
									</Box>
								</Box>
								<Box width="100%" padding="8px 8px 8px 8px">
									<Box display="flex" flex-direction="column">
										<Text font="--base" margin="0 0 4px 0">
											E-posta
										</Text>
										<Input max-width="400px" width="100%" type="email" name="email" />
									</Box>
								</Box>
								<Box width="100%" padding="8px 8px 8px 8px">
									<Box display="flex" flex-direction="column">
										<Text font="--base" margin="0 0 4px 0">
											Telefon Numarası
										</Text>
										<Input max-width="400px" width="100%" type="tel" name="phone" />
									</Box>
								</Box>
								<Box padding="8px 8px 8px 8px" width="100%">
									<Box display="flex" flex-direction="column">
										<Text font="--base" margin="0 0 4px 0">
											Mesaj
										</Text>
										<Input width="100%" name="message" as="textarea" rows="4" />
									</Box>
								</Box>
								<Box width="100%" padding="8px 8px 8px 8px">
									<Box display="flex" flex-direction="column" align-items="flex-start">
										<Button background="--color-dark">
											Gönder
										</Button>
									</Box>
								</Box>
							</Box>
						</Formspree>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Headernew />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});